//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React        from 'react';
import Strings      from '../Strings';
import DataTable    from '../components/widgets/DataTable';
import Utils        from '../utils/Utils';

const COLUMNS = [
    {
        title               : Strings.productName,
        renderItem          : product => product.name,
    }, {
        title               : Strings.revenue,
        align               : 'right',
        defaultSortDirection: -1,
        renderItem          : product => Utils.formatUsd00(product.revenue),
        valueToCompare      : product => product.revenue,
    }
];

function joinProducts(products, current) {
    return products.map(product => ({
        id      : product.id,
        name    : product.name,
        revenue : current ? current.salesByProduct[product.id].total : 0,
    }));
}

export default props =>
    <DataTable  id          = {props.id}
                className   = {`flex-grow-1 ${props.className || ''}`}
                columns     = {COLUMNS}
                items       = {joinProducts(props.products, props.current)}
                itemKey     = {product => product.id}/>;