//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import Chart        from 'chart.js';
import React        from 'react';
import seedrandom   from 'seedrandom';
import Utils        from '../../utils/Utils';

const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May',
    'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default class TrendLineChart extends React.Component {

    constructor(props) {

        super(props);

        this.options = {
            legend: {
                labels: {
                    fontColor: '#ccc',
                },
            },
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            color: '#333',
                            drawBorder: false,
                        },
                        ticks: {
                            min: (this.props.months && this.props.months.length > 0 && (this.props.months[0].number - 0)) || 0,
                            max: (this.props.months && this.props.months.length > 0 && (this.props.months[this.props.months.length - 1].number + 0)) || 5,
                            fontColor: '#ccc',
                            fontSize: 10,
                            minRotation: 0,
                            maxRotation: 0,
                            stepSize: 1,
                            callback: value => MONTH_NAMES[value % 12] + ' ' + Math.floor(value / 12),
                        },
                        type: 'linear',
                    }
                ],
                yAxes: [
                    {
                        gridLines: {
                            color: '#333',
                            drawBorder: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: '#ccc',
                            fontSize: 10,
                            lineHeight: 3,
                            callback: value => Utils.formatUsd(value),
                        },
                    }
                ]
            },
            tooltips: {
                callbacks: {
                    label: (item, chart) => {
                        return ' ' + Utils.formatUsd00(item.yLabel);
                    },
                    title: (item, chart) => {
                        const value = Number(item[0].xLabel);
                        return [chart.datasets[item[0].datasetIndex].label, MONTH_NAMES[value % 12] + ' ' + Math.floor(value / 12)];
                    },
                }
            },
        };

        this.el = null;
        this.chart = null;
        this.makeData(this.props.months, this.props.products);

    }

    componentWillUnmount() {
        this.destroyChart();
    }

    createChart() {
        const ctx = this.el.getContext('2d');
        this.chart = new Chart(ctx, { type: 'line', data: this.data, options: this.options })
    }

    destroyChart() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }

    makeData(months, products) {

        months = months || [];
        products = products || [];

        const colors = products.map(product => {
            const rng = seedrandom('x' + product.name);
            const h = Math.floor(360 * rng());
            const s = '60%';
            const l = '50%';
            return `hsl(${h}, ${s}, ${l})`;
        });

        this.data = this.data || {};
        this.data.datasets = products.map((product, idx) => ({
            backgroundColor: colors[idx],
            borderColor: colors[idx],
            data: months.map(month => ({x: month.number, y: month.salesByProduct[product.id].total})),
            drawBorder: false,
            fill: false,
            label: product.name,
            pointRadius: 4,
            showLine: true,
        }));

    }

    onRefChanged(el) {
        if (this.el !== el) {
            const oldEl = this.el;
            this.el = el;
            if (oldEl) {
                this.destroyChart();
            }
            if (el) {
                this.createChart();
            }
        }
    }

    render() {
        return <div className={this.props.className} style={{overflow: 'hidden'}}>
            <canvas ref={el => (this.onRefChanged.bind(this))(el)}/>
        </div>;
    }

};