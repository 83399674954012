//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import Link                 from '../components/widgets/Link';
import logo                 from '../images/staffpad.png';
import smallLogo            from '../images/staffpad-small.png';
import SessionContext       from '../utils/SessionContext';
import                           './NavBar.css';

import {
    faCog,
    faKey,
    faProjectDiagram,
    faSignOutAlt,
    faUpload,
    faUserCircle,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';

const ADMIN_LINKS = [
    {href: '/admin/users',      text: 'Users',      icon: faUsers},
    {href: '/admin/products',   text: 'Products',   icon: faProjectDiagram},
    {href: '/admin/upload',     text: 'Upload',     icon: faUpload},
];

export default () => {

    const session = React.useContext(SessionContext);
    const smallClasses = session.user ? 'd-inline d-md-none' : 'd-none';
    const largeClasses = session.user ? 'd-none d-md-inline' : 'd-inline';

    return <>

        <nav className="navbar navbar-dark bg-dark fixed-top">

            <div className="container d-flex align-items-center">

                <Link href="/" className={`navbar-brand ${largeClasses}`}>
                    <img src={logo} alt="StaffPad Partner Dashboard"/>
                </Link>

                <Link href="/" className={`navbar-brand ${smallClasses}`}>
                    <img src={smallLogo} alt="StaffPad Partner Dashboard"/>
                </Link>

                <div className="navbar-brand flex-grow-1 text-center">
                    {session.user && session.user.name}
                </div>

                {session.user && session.user.isAdmin &&
                    ADMIN_LINKS.map(link =>
                        <div    key={link.href}
                                className={`d-none d-md-block navbar-link ${window.location.pathname.startsWith(link.href) ? 'active' : ''}`}>
                            <Link href={link.href}>{link.text}</Link>
                        </div>)}

                {session.user && session.user.isAdmin &&
                    <div className="dropdown d-block d-md-none">
                        <button type            = "button"
                                className       = "m-2 p-0 text-light text-medium btn-bare"
                                id              = "navbar-admin-dropdown-button"
                                data-toggle     = "dropdown">
                            <FontAwesomeIcon icon={faCog}/>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            {ADMIN_LINKS.map(link =>
                                <Link   key         = {link.text}
                                        href        = {link.href}
                                        className   = {`dropdown-item ${window.location.pathname.startsWith(link.href) ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={link.icon} className="dropdown-icon"/>
                                    {link.text}
                                </Link>
                            )}
                        </div>
                    </div>}

                {session.user &&
                    <div className="dropdown">
                        <button type            = "button"
                                className       = "m-2 p-0 text-light text-large btn-bare"
                                id              = "navbar-user-dropdown-button"
                                data-toggle     = "dropdown">
                            <FontAwesomeIcon icon={faUserCircle}/>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link href="/password" className="dropdown-item">
                                <FontAwesomeIcon icon={faKey} className="dropdown-icon"/>
                                Change Password
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link href="/logout" className="dropdown-item">
                                <FontAwesomeIcon icon={faSignOutAlt} className="dropdown-icon"/>
                                Log out
                            </Link>
                        </div>
                    </div>}

            </div>

        </nav>

    </>;

};