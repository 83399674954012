//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import                           './DataTable.css';

import {
    faSortAmountDown,
    faSortAmountUpAlt
} from '@fortawesome/free-solid-svg-icons';

export default class DataTable extends React.Component {

    constructor(props) {

        super(props);

        const sortColumn = this.pickSortColumn();

        this.state = {
            sortColumn      : sortColumn,
            sortDirection   : (sortColumn && sortColumn.defaultSortDirection) || 1,
        };

    }

    componentDidUpdate(prevProps) {
        if (prevProps.columns !== this.props.columns) {
            if (!this.state.sortColumn || (this.props.columns || []).indexOf(this.state.sortColumn) < 0) {
                const sortColumn = this.pickSortColumn();
                this.setState({sortColumn: sortColumn, sortDirection: (sortColumn && sortColumn.defaultSortDirection) || 1});
            }
        }
    }

    onColumnClick(column) {
        if (column.sortable || column.sortable === undefined) {
            if (this.state.sortColumn === column) {
                this.setState({sortDirection: -this.state.sortDirection});
            } else {
                this.setState({sortColumn: column, sortDirection: (column && column.defaultSortDirection) || 1});
            }
        }
    }

    pickSortColumn() {
        return (this.props.columns || []).find(column => column.sortable || column.sortable === undefined);
    }

    render() {

        const SortIcon = props =>
            <FontAwesomeIcon    icon={this.state.sortDirection > 0 ? faSortAmountUpAlt : faSortAmountDown}
                                className={`text-warning ${props.column.align === 'right' ? 'mr-2' : 'ml-2'} ${props.className || ''}`}/>;

        const SortIconBefore = props =>
            props.column.align === 'right' &&
                <SortIcon column={props.column} className={props.column === this.state.sortColumn ? '' : 'hidden'}/>;

        const SortIconAfter = props =>
            props.column.align !== 'right' &&
                <SortIcon column={props.column} className={props.column === this.state.sortColumn ? '' : 'hidden'}/>;

        return <>

            <table  id          = {this.props.id}
                    className   = {`data-table table table-sm borderless ${this.props.className || ''}`}
                    style       = {this.props.style}>

                <thead>
                    <tr>
                        {this.props.columns && this.props.columns.map(column =>
                            <th key         = {column.title}
                                scope       = "col"
                                className   = {`${column.align ? 'text-' + column.align : ''} ${column.className || ''}`}
                                onClick     = {this.onColumnClick.bind(this, column)}>
                                <SortIconBefore column={column}/>
                                {column.title}
                                <SortIconAfter column={column}/>
                            </th>
                        )}
                    </tr>
                </thead>

                <tbody>
                    {this.sortedItems().map(item =>
                        <tr key={this.props.itemKey ? this.props.itemKey(item) : item}
                            onClick={this.props.onItemClick && (() => this.props.onItemClick(item))}>
                            {this.props.columns && this.props.columns.map(column =>
                                <td key         = {column.title}
                                    className   = {`${column.align ? 'text-' + column.align : ''} ${column.className || ''}`}>
                                    {column.renderItem ? column.renderItem(item) : item}
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>

            </table>

        </>;
    }

    sortedItems() {

        const items = (this.props.items || []).slice();
        const column = this.state.sortColumn;

        if (column) {
            items.sort((a, b) => {
                let direction = this.state.sortDirection;
                let result;
                if (column.compare) {
                    result = column.compare(a, b);
                } else {
                    let ca = null, cb = null;
                    if (column.valueToCompare) {
                        if (Array.isArray(column.valueToCompare)) {
                            for (let i = 0; i < column.valueToCompare.length && ca === cb; ++i) {
                                if (typeof column.valueToCompare[i] === 'function') {
                                    ca = column.valueToCompare[i](a);
                                    cb = column.valueToCompare[i](b);
                                } else {
                                    direction = column.valueToCompare[i];
                                }
                            }
                        } else {
                            ca = column.valueToCompare(a);
                            cb = column.valueToCompare(b);
                        }
                    } else if (column.renderItem) {
                        ca = column.renderItem(a);
                        cb = column.renderItem(b);
                    } else {
                        ca = a;
                        ca = b;
                    }
                    if (ca > cb) { result = 1; }
                    else if (ca < cb) { result = -1; }
                    else { result = 0; }
                }
                if (direction < 0) {
                    result = -result;
                }
                return result;
            });
        }
        return items;
    }

};