//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React from 'react';
import './CenterScreen.css';

//
// A container which centers its content horizontally and vertically in its container.
//
// Properties:
// id           - (optional) The element ID to assign to the container.
// className    - (optional) CSS class names to assign to the container.
// children     - Content to render in the container.
//
export default props =>
    <div id={props.id} className={`center-screen ${props.className || ''}`}>
        {props.children}
    </div>;