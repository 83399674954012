//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React            from 'react';
import LoadingPage      from '../pages/LoadingPage';
import Backend          from '../utils/Backend';
import SessionContext   from '../utils/SessionContext';
import Utils            from '../utils/Utils';

// In order to keep the loading page from flashing away too quickly (and distractingly),
// we display it for a minimum period of time even if the session check is done faster.
const DEFAULT_MIN_HOLD_TIME = 1000; // ms

//
// The SessionCheck component is the outermost component and the SessionContext provider for the
// application. When the app is loaded, the component makes a backend call to determine whether a
// session is active. If so, the user data is stored in the SessionContext value. The
// SessionContext value also holds a set() function which can be used by login/logout components
// to update the user data in the SessionContext value. While the session check is in progress,
// the <LoadingPage> is rendered. Afterward, the children property is rendered.
//
// Properties:
// minHoldTime  - (optional) The minimum interval (in seconds) to wait before rendering the
//                content, even if the session check fulfills sooner. The default is
//                DEFAULT_MIN_HOLD_TIME.
// children     - Content to render after the session check has completed.
//
export default class SessionCheck extends React.Component {

    constructor(props) {

        super(props);

        this.backend = new Backend();
        this.backend.onUnauthorized = () => this.setUser(null);

        this.state = {
            session: {
                backend: this.backend,
                user: null,
                set: this.setUser.bind(this)
            },
            loading: true,
        };

    }

    setUser(user) {
        if (user !== this.state.user) {
            this.setState({
                session: {
                    backend: this.backend,
                    user: user,
                    set: this.setUser.bind(this),
                }
            });
        }
    }

    async componentDidMount() {

        try {

            const user = (await Promise.all([
                this.backend.getSession(),
                Utils.sleep(this.props.minHoldTime ? (this.props.minHoldTime * 1000) : DEFAULT_MIN_HOLD_TIME)
            ]))[0];

            if (user) {
                this.setUser(user);
            }

        } catch (error) {
            console.error(error);
        } finally {
            this.setState({loading: false});
        }

    }

    render() {
        return this.state.loading
            ?   <LoadingPage/>
            :   <SessionContext.Provider value={this.state.session}>
                    {this.props.children}
                </SessionContext.Provider>;
    }

};