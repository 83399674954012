//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import AppFrame             from '../components/AppFrame';
import ErrorBox             from '../components/widgets/ErrorBox';
import SessionContext       from '../utils/SessionContext';
import Utils                from '../utils/Utils';

import {
    faCheck,
    faFileCsv,
    faUpload
} from '@fortawesome/free-solid-svg-icons';

export default class UploadPage extends React.Component {

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.state = {filename: null, working: false, error: null, success: null};
    }

    onFileChanged() {
        const files = this.fileInput.current.files;
        if (files.length > 0) {
            this.setState({filename: files[0].name});
        } else {
            this.setState({filename: null});
        }
    }

    async onUploadButtonClicked() {
        this.setState({error: null, success: null, working: true});
        try {
            const result = await this.context.backend.upload(this.fileInput.current.files[0])
            if (result && result.errors && result.errors.length) {
                this.setState({error: result.errors.join(' ')});
            } else {
                this.setState({success: 'Success!'});
            }
        } catch (error) {
            this.setState({error: 'An error occurred.'});
        } finally {
            this.setState({working: false}, Utils.scrollToBottom);
        }
    }

    render() {
        return <AppFrame>
            <div className="row mb-4">
                <div className="col">
                    <form>
                        <h3 className="text-light">Upload Report</h3>
                        <p className="mt-4">
                            Select a CSV file to upload. The file must contain the following columns:
                        </p>
                        <ul>
                            <li><tt>product_id</tt></li>
                            <li><tt>start_date</tt></li>
                            <li><tt>end_date</tt></li>
                            <li><tt>converted_payment_revenue</tt></li>
                            <li><tt>financial_revenue</tt></li>
                            <li><tt>estimated_revenue</tt></li>
                            <li><tt>best_estimate</tt></li>
                        </ul>
                        <p>
                            All start and end dates must fall in the same calendar month; i.e., a file
                            cannot contain payment data that spans more than one calendar month.
                            <span className="text-danger">Any existing data for that calendar month
                            will be replaced.</span> Only payment records that match a currently defined
                            product will be imported, and those records will permanently acquire the
                            Partner Share value from that product. Future changes to the product's
                            Partner Share value will have no effect unless the data is re-uploaded.
                        </p>
                        <div className="mb-3 d-flex align-items-center">
                            <label className="m-0">
                                <input  type        = "file"
                                        style       = {{display: 'none'}}
                                        ref         = {this.fileInput}
                                        onChange    = {this.onFileChanged.bind(this)}
                                        disabled    = {this.state.working}/>
                                <div className={`btn btn-primary ${this.state.working ? 'disabled' : null} text-nowrap`}>
                                    <FontAwesomeIcon icon={faFileCsv} className="mr-2"/>
                                    Select File
                                </div>
                            </label>
                            <input  type        = "text"
                                    className   = "form-control flex-grow-1 mx-2"
                                    placeholder = ""
                                    value       = {this.state.filename || ''}
                                    readOnly    = {true}/>
                            <button type        = "button"
                                    className   = "btn btn-info text-nowrap"
                                    disabled    = {this.state.working || !this.state.filename}
                                    onClick     = {this.onUploadButtonClicked.bind(this)}>
                                <FontAwesomeIcon icon={faUpload} className="mr-2"/>
                                Upload
                            </button>
                        </div>

                        {this.state.working ? <div className="spinner-grow text-info" role="status"><span className="sr-only">Uploading...</span></div> : null}
                        {this.state.success ? <p className="text-success"><FontAwesomeIcon icon={faCheck} className="mr-2"/> {this.state.success}</p> : null}
                        {this.state.error && <ErrorBox error={this.state.error}/>}

                    </form>
                </div>
            </div>
        </AppFrame>;
    }
};

UploadPage.contextType = SessionContext;