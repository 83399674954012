//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import ResizeSensor         from 'css-element-queries/src/ResizeSensor';
import                           './Carousel.css';

import {
    faCaretLeft,
    faCaretRight
} from '@fortawesome/free-solid-svg-icons';

export default class Carousel extends React.Component {

    constructor(props) {

        super(props);

        this.el = null;
        this.root = this.createRoot();

    }

    centerItem($item, animate) {
        const $scroller = $item.parent();
        const $contents = $scroller.parent();
        const dx = $contents[0].clientWidth / 2;
        const ax = $item[0].offsetLeft + $item[0].offsetWidth / 2;
        const style = {left: (dx - ax) + 'px'};
        if (animate || animate === undefined) {
            $scroller.animate(style, 150);
        } else {
            $scroller.css(style);
        }
        $scroller.children('.item').removeClass('active');
        $item.addClass('active');
    }

    componentDidUpdate(prevProps) {
        if (this.props.items !== prevProps.items) {
            this.depopulate();
            this.populate();
        } else if (this.el && this.props.selected !== prevProps.selected && this.props.items) {
            const i = this.props.items.indexOf(this.props.selected);
            if (i >= 0) {
                this.centerItem(window.$(window.$(this.el).find('.item')[i]));
            }
        }
    }

    createRoot() {
        return <>
            <div    id          = {this.props.id}
                    className   = {`carousel ${this.props.className || ''}`}
                    style       = {this.props.style}
                    ref         = {this.onRefChange.bind(this)}>
                <div className="prev">
                    <FontAwesomeIcon icon={faCaretLeft}/>
                </div>
                <div className="contents">
                    <div className="scroller"></div>
                </div>
                <div className="next">
                    <FontAwesomeIcon icon={faCaretRight}/>
                </div>
            </div>
        </>;
    }

    depopulate() {
        this.resizeSensor.detach();
        const $carousel = window.$(this.el);
        $carousel.find('.scroller').empty();
        $carousel.children('.prev').off('click');
        $carousel.children('.next').off('click');
    }

    onRefChange(el) {
        if (this.el !== el) {
            if (this.el) {
                this.depopulate();
            }
            this.el = el;
            if (this.el) {
                this.populate();
            }
        }
    }

    populate() {

        let selectedItem = undefined;
        let $selected = null;

        const $carousel = window.$(this.el);
        const $scroller = $carousel.find('.scroller');
        (this.props.items || []).forEach(item => {
            const content = this.props.renderItem ? this.props.renderItem(item) : item;
            const $item = window.$('<div class="item">' + content + '</div>');
            if (item === this.props.selected) {
                $item.addClass('active');
                $selected = $item;
                selectedItem = item;
            } else if (!$selected) {
                $selected = $item;
                selectedItem = item;
            }
            $scroller.append($item);
            $item.click(() => this.props.onChange && this.props.onChange(item));
        });
        if ($selected) {
            this.centerItem($selected, false);
        }

        $carousel.children('.prev').click(e => {
            if (this.props.onChange && this.props.items) {
                const i = this.props.items.indexOf(this.props.selected);
                if (i >= 1) {
                    this.props.onChange(this.props.items[i - 1]);
                }
            }
        });

        $carousel.children('.next').click(e => {
            if (this.props.onChange && this.props.items) {
                const i = this.props.items.indexOf(this.props.selected);
                if (i >= 0 && i + 1 < this.props.items.length) {
                    this.props.onChange(this.props.items[i + 1]);
                }
            }
        });

        if (this.props.onChange) {
            this.props.onChange(selectedItem);
        }

        this.resizeSensor = new ResizeSensor(this.el, () =>
            this.centerItem(window.$(this.el).find('.item.active'), false));

    }

    render() {
        return this.root;
    }

};