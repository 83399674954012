//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import Async                from 'react-async';
import { useHistory }       from "react-router-dom";
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import Strings              from '../Strings';
import AppFrame             from '../components/AppFrame';
import DataTable            from '../components/widgets/DataTable';
import ErrorBox             from '../components/widgets/ErrorBox';
import Spinner              from '../components/widgets/Spinner';
import SessionContext       from '../utils/SessionContext';

import {
    faCheck,
    faPlusSquare,
    faSyncAlt,
    faTimes
} from '@fortawesome/free-solid-svg-icons';

export default () => {

    const history = useHistory();
    const session = React.useContext(SessionContext);

    return <Async   promiseFn   = {({backend}) => backend.getUsers()}
                    backend     = {session.backend}>{({isPending, reload}) =>

        <AppFrame>
            <div className="row">
                <div className="col">

                    <div className="d-flex align-items-center mb-4">

                        <h3 className="text-light m-0 p-0 flex-grow-1">{Strings.users}</h3>

                        <Async.Pending>
                            <Spinner className="mr-2 spinner-border-sm"/>
                        </Async.Pending>

                        <button className   = "btn btn-primary ml-1"
                                onClick     = {reload}
                                disabled    = {isPending}
                                title       = {Strings.refreshUserList}>
                            <FontAwesomeIcon icon={faSyncAlt} className="mr-2"/>
                            {Strings.refresh}
                        </button>

                        <button className   = "btn btn-info ml-1"
                                onClick     = {() => history.push('/admin/users/add')}
                                title       = {Strings.addUser}>
                            <FontAwesomeIcon icon={faPlusSquare} className="mr-2"/>
                            {Strings.add}
                        </button>

                    </div>

                    <Async.Rejected>
                        <ErrorBox error={Strings.serverError}/>
                    </Async.Rejected>

                    <Async.Resolved persist>{users => users.length === 0

                        ?   <div className="alert alert-primary">
                                {Strings.noUsers}
                            </div>

                        :   <DataTable
                                className   = "table-hover"
                                items       = {users}
                                itemKey     = {user => user.id}
                                onItemClick = {user => history.push('/admin/users/' + user.id)}
                                columns     = {[
                                    {
                                        title           : Strings.username,
                                        renderItem      : user => user.username,
                                    }, {
                                        title           : Strings.name,
                                        className       : 'd-none d-md-table-cell',
                                        renderItem      : user => user.name,
                                    }, {
                                        title           : Strings.lastActivity,
                                        className       : 'd-none d-lg-table-cell',
                                        renderItem      : user => user.lastActivity || 'Never logged in',
                                    }, {
                                        title           : Strings.admin,
                                        align           : 'right',
                                        renderItem      : user => user.isAdmin
                                            ? <FontAwesomeIcon icon={faCheck} className="text-success"/>
                                            : <FontAwesomeIcon icon={faTimes} className="text-dark"/>,
                                        valueToCompare  : [user => user.isAdmin ? 'a' : 'b', 1, user => user.username],
                                    }
                            ]}/>

                    }</Async.Resolved>

                </div>
            </div>
        </AppFrame>

    }</Async>;

};