//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

export default {
    add                     : 'Add',
    addProduct              : 'Add a new product',
    addUser                 : 'Add a new user',
    admin                   : 'Admin',
    changePassword          : 'Change Password',
    confirmPassword         : 'Confirm password',
    currentPassword         : 'Current password',
    enterCurrentPassword    : 'Enter your current password',
    enterNewPassword        : 'Enter a new password',
    enterNewPassword2       : 'Enter your new password again for confirmation',
    exportCsv               : 'Export CSV',
    exportPdf               : 'Export PDF',
    invalidCredentials      : 'Invalid credentials.',
    lastActivity            : 'Last Activity',
    logIn                   : 'Log In',
    monthly                 : 'Monthly',
    name                    : 'Name',
    newPassword             : 'New password',
    noProducts              : 'There are no products in the database.',
    noUsers                 : 'There are no users in the database.',
    password                : 'Password',
    passwordMismatch        : 'The passwords you entered do not match.',
    products                : 'Products',
    productName             : 'Product Name',
    quarterly               : 'Quarterly',
    refresh                 : 'Refresh',
    refreshProductList      : 'Refresh the product list',
    refreshUserList         : 'Refresh the user list',
    revenue                 : 'Revenue',
    serverError             : 'An error occurred contacting the server. Please refresh or try again later.',
    share                   : 'Share',
    stayLoggedIn            : 'Stay Logged In',
    total                   : 'Total',
    trends                  : 'Trends',
    username                : 'Username',
    users                   : 'Users',
    viewAs                  : 'View As',
    yearly                  : 'Yearly',
};