//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import Chart        from 'chart.js';
import React        from 'react';
import seedrandom   from 'seedrandom';
import Utils        from '../../utils/Utils';

export default class ProductBarChart extends React.Component {

    constructor(props) {

        super(props);

        this.options = {
            layout: {
                padding: {
                    bottom: 0,
                }
            },
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            callback: value => (value || '').split(' '),
                            fontColor: '#ccc',
                            fontSize: 10,
                            minRotation: 0,
                            maxRotation: 0,
                        },
                    }
                ],
                yAxes: [
                    {
                        gridLines: {
                            color: '#333',
                            drawBorder: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: '#ccc',
                            fontSize: 10,
                            lineHeight: 3,
                            callback: value => Utils.formatUsd(value),
                        },
                    }
                ]
            },
            tooltips: {
                callbacks: {
                    label: item => ' ' + Utils.formatUsd00(item.yLabel),
                }
            },
        };

        this.el = null;
        this.chart = null;
        this.makeData(this.props.item, this.props.products);

    }

    componentDidUpdate() {
        this.makeData(this.props.item, this.props.products);
    }

    componentWillUnmount() {
        this.destroyChart();
    }

    createChart() {
        const ctx = this.el.getContext('2d');
        this.chart = new Chart(ctx, { type: 'bar', data: this.data, options: this.options })
    }

    destroyChart() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }

    makeData(item, products) {

        products = products || [];

        const colors = products.map(product => {
            const rng = seedrandom('x' + product.name);
            const h = Math.floor(360 * rng());
            const s = '60%';
            const l = '50%';
            return `hsl(${h}, ${s}, ${l})`;
        });

        this.data = this.data || {};

        this.data.labels = products.map(product => product.name);

        this.data.datasets = [{
            label: null,
            backgroundColor: colors,
            borderWidth: 0,
            data: products.map(product => item.salesByProduct[product.id].total)
        }];

        if (this.chart) {
            this.chart.update();
        }

    }

    onRefChanged(el) {
        if (this.el !== el) {
            const oldEl = this.el;
            this.el = el;
            if (oldEl) {
                this.destroyChart();
            }
            if (el) {
                this.createChart();
            }
        }
    }

    render() {
        return <div className={this.props.className} style={{overflow: 'hidden'}}>
            <canvas ref={el => (this.onRefChanged.bind(this))(el)}/>
        </div>;
    }

};