//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                        from 'react';
import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome';
import { faExclamationTriangle }    from '@fortawesome/free-solid-svg-icons';

export default props =>

    <p  id          = {props.id}
        className   = {`${props.error ? 'bg-danger' : ''} text-center text-light p-2 ${props.className || ''}`}
        style       = {{borderRadius: '0.25rem', ...props.style}}>

        {props.error
            ? <><FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/>{props.error}</>
            : <>&nbsp;</>}

    </p>;