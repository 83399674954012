//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React from 'react';

export default props =>

    <div    id          = {props.id}
            className   = {`btn-group ${props.className || ''}`}
            style       = {props.style}>

        {props.items.map(item =>

            <button key         = {props.itemKey ? props.itemKey(item) : item}
                    type        = "button"
                    className   = {`btn border-0 ${item === props.selected ? 'btn-info' : 'btn-primary'}`}
                    onClick     = {() => props.onChange && props.onChange(item)}>

                {props.renderItem ? props.renderItem(item) : item}

            </button>

        )}

    </div>;