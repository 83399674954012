//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import axios        from 'axios';
import HttpStatus   from 'http-status-codes';

//
// Encapsulates all backend AJAX requests. All functions are asynchronous and return promises.
//
export default class Backend {

    addOptions(options) {
        options = options || {};
        options.nullOnStatus = options.nullOnStatus || [];
        options.okOnStatus = options.okOnStatus || [];
        options.validateStatus = this.validateStatus.bind(this, options);
        return options;
    }

    validateStatus(options, status) {
        if (options.nullOnStatus.indexOf(status) >= 0 || options.okOnStatus.indexOf(status) >= 0) {
            return true;
        } else if (status === HttpStatus.UNAUTHORIZED && this.onUnauthorized) {
            this.onUnauthorized();
        }
        return status >= 200 && status < 400;
    }

    async delete(url, options) {
        const reply = await axios.delete(url, options = this.addOptions(options));
        return options.nullOnStatus.indexOf(reply.status) >= 0 ? null : reply.data;
    }

    async get(url, options) {
        const reply = await axios.get(url, options = this.addOptions(options));
        return options.nullOnStatus.indexOf(reply.status) >= 0 ? null : reply.data;
    }

    async post(url, data, options) {
        const reply = await axios.post(url, data, options = this.addOptions(options));
        return options.nullOnStatus.indexOf(reply.status) >= 0 ? null : reply.data;
    }

    async changePassword(currentPassword, newPassword) {
        await this.post('/api/password', {
            currentPassword: currentPassword,
            newPassword: newPassword
        });
    }

    async createProduct(product) {
        await this.post('/api/admin/products', product);
    }

    async createUser(user) {
        await this.post('/api/admin/users', user);
    }

    async deleteProduct(productId) {
        await this.delete('/api/admin/products/' + productId);
    }

    async deleteUser(userId) {
        await this.delete('/api/admin/users/' + userId);
    }

    async getProduct(id) {
        if (isNaN(id = parseInt(id))) {
            return null;
        }
        return await this.get('/api/admin/products/' + id,
            {nullOnStatus: [HttpStatus.NOT_FOUND]});
    }

    async getProducts() {
        return await this.get('/api/admin/products');
    }

    async getSession() {
        return await this.get('/api/session',
            {nullOnStatus: [HttpStatus.UNAUTHORIZED]});
    }

    async getSummary(userId) {
        let url = '/api/summary';
        if (userId) {
            url += '?forUserId=' + userId;
        }
        return await this.get(url);
    }

    async getUser(id) {
        if (isNaN(id = parseInt(id))) {
            return null;
        }
        return await this.get('/api/admin/users/' + id,
            {nullOnStatus: [HttpStatus.NOT_FOUND]});
    }

    async getUsers() {
        return await this.get('/api/admin/users');
    }

    async login(username, password, remember) {
            return await this.post('/api/login', {
            username: username,
            password: password,
            remember: remember
        }, {nullOnStatus: [HttpStatus.UNAUTHORIZED]});
    }

    async logout() {
        await this.post('/api/logout');
    }

    async updateProduct(product) {
        await this.post('/api/admin/products/' + product.id, product);
    }

    async updateUser(user) {
        await this.post('/api/admin/users/' + user.id, user);
    }

    async upload(file) {
        var data = new FormData();
        data.append('file', file);
        return await this.post('/api/admin/upload', data, {
            headers: {'Content-Type': 'multipart/form-data'},
            okOnStatus: [HttpStatus.UNPROCESSABLE_ENTITY]
        });
    }

};