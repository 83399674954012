//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import Chart    from 'chart.js';
import React    from 'react';
import Utils    from '../../utils/Utils';

export default class PlatformPieChart extends React.Component {

    constructor(props) {

        super(props);

        this.options = {
            layout: {
                padding: {
                    top: 0,
                    bottom: 30,
                }
            },
            legend: {
                display: true,
                labels: {
                    fontColor: '#ccc',
                    fontSize: 10,
                },
            },
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    label: (item, chart) => {
                        const dataset = chart.datasets[item.datasetIndex];
                        const dollars = Utils.formatUsd00(chart.datasets[item.datasetIndex].data[item.index]);
                        const label = ' ' + chart.labels[item.index] + ': ';
                        if (dataset.total > 0) {
                            return label + (100 * dataset.data[item.index] / dataset.total).toFixed(0) + '% (' + dollars + ')';
                        } else {
                            return label + dollars;
                        }
                    },
                }
            },
        };

        this.el = null;
        this.chart = null;
        this.makeData(this.props.item, this.props.platforms);

    }

    componentDidUpdate() {
        this.makeData(this.props.item, this.props.platforms);
    }

    componentWillUnmount() {
        this.destroyChart();
    }

    createChart() {
        const ctx = this.el.getContext('2d');
        this.chart = new Chart(ctx, { type: 'pie', data: this.data, options: this.options })
    }

    destroyChart() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }

    makeData(item, platforms) {

        platforms = platforms || [];

        const colors = ['#0c8', '#08c', '#80c', '#8c0', '#c80', '#c08'];

        this.data = this.data || {};

        this.data.labels = platforms.map(platform => Utils.titleCase(platform));

        this.data.datasets = [{
            label: null,
            backgroundColor: colors,
            borderWidth: 1,
            borderColor: '#000',
            total: platforms.reduce((sum, platform) => sum += item.salesByPlatform[platform], 0),
            data: platforms.map(platform => item.salesByPlatform[platform])
        }];

        if (this.chart) {
            this.chart.update();
        }

    }

    onRefChanged(el) {
        if (this.el !== el) {
            const oldEl = this.el;
            this.el = el;
            if (oldEl) {
                this.destroyChart();
            }
            if (el) {
                this.createChart();
            }
        }
    }

    render() {
        return <div className={this.props.className} style={{overflow: 'hidden'}}>
            <canvas ref={el => (this.onRefChanged.bind(this))(el)}/>
        </div>;
    }

};