//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React            from 'react';
import { withRouter }   from 'react-router-dom';

import './Link.css';

class Link extends React.Component {

    async onClick() {
        if (!this.props.disabled && this.props.href) {
            this.props.history.push(this.props.href);
        }
    }

    render() {

        return (
            <div    className={`link ${this.props.disabled ? 'link-disabled' : ''} ${this.props.className}`}
                    onClick={this.onClick.bind(this)}>
                {this.props.children}
            </div>
        );
    }

};

export default withRouter(Link);