//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import { withRouter }       from 'react-router-dom';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import AppFrame             from '../components/AppFrame';
import ErrorBox             from '../components/widgets/ErrorBox';
import Spinner              from '../components/widgets/Spinner';
import SessionContext       from '../utils/SessionContext';
import Utils                from '../utils/Utils';

import { faCheck } from '@fortawesome/free-solid-svg-icons';

class ChangePasswordPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            error: null,
            currentPassword: '',
            newPassword1: '',
            newPassword2: ''
        };

    }

    async onChangePasswordClick() {
        if (this.state.newPassword1 !== this.state.newPassword2) {
            this.setState({error: 'The new passwords you entered do not match.'}, Utils.scrollToTop);
        } else if (this.state.currentPassword === '' || this.state.newPassword1 === '') {
            this.setState({error: 'Please enter your current password and a new password.'}, Utils.scrollToTop);
        } else {
            this.setState({error: null, loading: true});
            try {
                await this.context.backend.changePassword(
                    this.state.currentPassword,
                    this.state.newPassword1);
                this.props.history.push('/');
            } catch (error) {
                this.setState({loading: false, error: 'An error occurred. Please ensure you entered your current password correctly.'}, Utils.scrollToTop);
            }
        }
    }

    render() {

        return <AppFrame>

            <div className="row">

                <div className="col">

                    <h3 className="text-light mb-2 p-0">Change Password</h3>

                    <ErrorBox className="my-3" error={this.state.error}/>

                    <form>

                        <div className="form-group d-flex align-items-center">
                            <label  htmlFor="input-current-password"
                                    className="mr-3 my-0 text-nowrap">
                                Current password
                            </label>
                            <input  type            = "password"
                                    id              = "input-current-password"
                                    className       = "form-control flex-grow-1"
                                    placeholder     = "Enter your current password"
                                    disabled        = {this.state.loading}
                                    value           = {this.state.currentPassword}
                                    onChange        = {Utils.onFieldChange.bind(this, 'currentPassword')}
                                    autoComplete    = "current-password"/>
                        </div>

                        <div className="form-group d-flex align-items-center">
                            <label  htmlFor="input-new-password-1"
                                    className="d-inline-block mr-3 my-0 text-nowrap">
                                New password
                            </label>
                            <input  type            = "password"
                                    id              = "input-new-password-1"
                                    className       = "form-control flex-grow-1"
                                    placeholder     = "Enter a new password"
                                    disabled        = {this.state.loading}
                                    value           = {this.state.newPassword1}
                                    onChange        = {Utils.onFieldChange.bind(this, 'newPassword1')}
                                    autoComplete    = "new-password"/>
                        </div>

                        <div className="form-group d-flex align-items-center">
                            <label  htmlFor="input-new-password-2"
                                    className="d-inline-block mr-3 my-0 text-nowrap">
                                Confirm password
                            </label>
                            <input  type            = "password"
                                    id              = "input-new-password-2"
                                    className       = "form-control flex-grow-1"
                                    placeholder     = "Enter your new password again for confirmation"
                                    disabled        = {this.state.loading}
                                    value           = {this.state.newPassword2}
                                    onChange        = {Utils.onFieldChange.bind(this, 'newPassword2')}
                                    autoComplete    = "new-password"/>
                        </div>

                        <div className="form-group d-flex align-items-center mt-5">
                            <button type        = "button"
                                    className   = "btn btn-info"
                                    onClick     = {this.onChangePasswordClick.bind(this)}
                                    disabled    = {this.state.loading}>
                                <FontAwesomeIcon icon={faCheck} className="mr-2"/>
                                Change Password
                            </button>
                            <div className="flex-grow-1 text-right">
                                {this.state.loading && <Spinner/>}
                            </div>
                        </div>

                    </form>

                </div>

            </div>

        </AppFrame>;
    }

}

ChangePasswordPage.contextType = SessionContext;
export default withRouter(ChangePasswordPage);