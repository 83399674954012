//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

const ids = {};

const usdFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0});
const usdFormatter00 = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

export default class Utils {

    //
    // Formats a numeric value as a currency string in the form "$X,XXX" rounded to the nearest
    // whole dollar.
    //
    static formatUsd(value) {
        return usdFormatter.format(value);
    }

    //
    // Formats a numeric value as a currency string in the form "$X,XXX.XX" rounded to the nearest
    // whole cent.
    //
    static formatUsd00(value) {
        return usdFormatter00.format(value);
    }

    //
    // Sets a state property (via this.setState()) to the value of an event's target element.
    // Supported target elements are text, password, number and checkbox input elements.
    //
    static onFieldChange(property, e) {
        const state = {};
        if (e.target.type === 'checkbox') {
            state[property] = e.target.checked;
        } else {
            state[property] = e.target.value;
        }
        this.setState(state);
    }

    //
    // Scrolls the application root element to the bottom.
    //
    static scrollToBottom() {
        const root = window.document.getElementById('root');
        root.scrollTo(0, root.scrollHeight);
    }

    //
    // Scrolls the application root element to the top.
    //
    static scrollToTop() {
        window.document.getElementById('root').scrollTo(0, 0);
    }

    //
    // Returns a promise that is resolved after the specified time.
    //
    static sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    //
    // Converts a string to Title Case.
    //
    static titleCase(str) {
        return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    }

    //
    // Returns a unique HTML element ID. If a prefix is given, the ID is of the form prefix-#
    // where # is a unique counter. Otherwise, the ID is simply a numeric counter.
    //
    static uniqueId(prefix) {
        if (!ids[prefix]) {
            ids[prefix] = 0;
        }
        const id = ++ids[prefix];
        return `${prefix ? (prefix + '-') : ''}${id}`;
    }

};