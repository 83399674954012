//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import { Route, Redirect }  from 'react-router-dom';
import SessionContext       from '../utils/SessionContext';

//
// A wrapper for the <Route> component which adds additional `loginRequired` and `adminRequired`
// properties. If `loginRequired` is true and the user is not logged in, a <Redirect> to `/login`
// is issued instead. If `adminRequired` is true and the user is not logged in or is not an
// administrator, a <Redirect> to `/` is issued instead. `adminRequired` implies `loginRequired`.
//
// Properties:
//     loginRequired    - True if the user must be logged in to access this route.
//     adminRequired    - True if the user must be logged in as an administrator to access this route.
//     (all others)     - Passed on to the <Route> component.
//
export default props => {

    const session = React.useContext(SessionContext);
    const isLoggedIn = !!session.user;
    const isAdmin = isLoggedIn && session.user.isAdmin;

    if ((props.loginRequired || props.adminRequired) && !isLoggedIn) {
        return <Redirect to="/login"/>;
    } else if (props.adminRequired && !isAdmin) {
        return <Redirect to="/"/>;
    } else {
        const { loginRequired, adminRequired, ...childProps } = props;
        return <Route {...childProps}/>;
    }

};