//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import Strings              from '../Strings';
import AppFrame             from '../components/AppFrame';
import ProductSummaryTable  from '../components/ProductSummaryTable';
import PlatformPieChart     from '../components/charts/PlatformPieChart';
import ProductBarChart      from '../components/charts/ProductBarChart';
import TrendLineChart       from '../components/charts/TrendLineChart';
import ButtonGroup          from '../components/widgets/ButtonGroup';
import Carousel             from '../components/widgets/Carousel';
import ErrorBox             from '../components/widgets/ErrorBox';
import Select               from '../components/widgets/Select';
import Spinner              from '../components/widgets/Spinner';
import SessionContext       from '../utils/SessionContext';
import Utils                from '../utils/Utils';

import { faFileCsv, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const CURRENT_QUARTER = () => {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = now.getUTCMonth();
    const quarter = year * 4 + Math.floor(month / 3);
    return quarter;
};

export default class HomePage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading     : false,
            error       : null,
            months      : [],
            platforms   : [],
            products    : [],
            quarters    : [],
            years       : [],
            users       : [],
            current     : null,
            choices     : [null],
            viewMode    : 'quarters',
            viewAs      : null,
        };

    }

    componentDidMount() {
        if (this.context.user.isAdmin) {
            this.setState({viewAs: this.context.user});
        }
        this.loadData();
    }

    async loadData() {
        this.setState({loading: true, error: null});
        try {
            const result = await this.context.backend.getSummary(this.state.viewAs && this.state.viewAs.id);
            const viewMode = this.state.viewMode;
            this.setState({
                months      : result.months,
                platforms   : result.platforms,
                products    : result.products,
                quarters    : result.quarters,
                years       : result.years,
                users       : result.users,
                current     : result[viewMode].length === 0 ? null : result[viewMode][result[viewMode].length - 1],
                choices     : [...result[viewMode], null],
            });
        } catch (error) {
            console.error(error);
            this.setState({error: Strings.serverError});
        } finally {
            this.setState({loading: false});
        }
    }

    onExportCsvClick() {
        if (this.state.current) {
            const modeKeys = {months: 'month', quarters: 'quarter', years: 'year'};
            let url = '/api/csv/' + modeKeys[this.state.viewMode] + '/' + this.state.current.number;
            if (this.state.viewAs) {
                url += '?forUserId=' + this.state.viewAs.id;
            }
            const a = window.document.createElement('a');
            a.href = url;
            a.click();
        }
    }

    onExportPdfClick(regenerate, getNewRate) {
        console.log('click');
        if (this.state.current) {
            const modeKeys = {months: 'month', quarters: 'quarter', years: 'year'};
            let url = '/api/pdf/' + modeKeys[this.state.viewMode] + '/' + this.state.current.number;
            const args = [];
            if (this.state.viewAs) {
                args.push('forUserId=' + this.state.viewAs.id);
            }
            if (this.context.user.isAdmin) {
                if (regenerate) {
                    args.push('regenerate=1');
                }
                if (getNewRate) {
                    args.push('getNewRate=1');
                }
            }
            if (args.length > 0) {
                url = url + '?' + args.join('&');
            }
            const a = window.document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.rel = 'noopener noreferrer';
            a.click();
        }
    }

    onExportPdfPress() {
        console.log('press');
        this.buttonPressTimer = setTimeout(() => this.onExportPdfClick(true), 1000);
    }

    onExportPdfRelease() {
        console.log('release');
        if (this.buttonPressTimer) {
            clearTimeout(this.buttonPressTimer);
        }
    }

    onPageChange(item) {
        this.setState({current: item});
    }

    onViewAsChange(user) {
        this.setState({viewAs: user}, () => {
            this.loadData();
        });
    }

    onViewModeChange(mode) {

        if (this.state.viewMode !== mode) {

            const choices = [...this.state[mode], null];
            let current = null;
            let n = null;

            if (this.state.current) {
                if (this.state.viewMode === 'months') {
                    n = this.state.current.number;
                } else if (this.state.viewMode === 'quarters') {
                    n = this.state.current.number * 3 + 2;
                } else if (this.state.viewMode === 'years') {
                    n = this.state.current.number * 12 + 11;
                }
                if (n) {
                    if (mode === 'quarters') {
                        n = Math.floor(n / 3);
                    } else if (mode === 'years') {
                        n = Math.floor(n / 12);
                    }
                    for (let i = choices.length - 1; i >= 0; --i) {
                        if (choices[i] && choices[i].number <= n) {
                            current = choices[i];
                            break;
                        }
                    }
                }
            }

            this.setState({
                viewMode: mode,
                choices: choices,
                current: current,
            })

        }

    }

    render() {

        if (this.state.loading) {
            return <>
                <AppFrame>
                    <div className="text-center">
                        <Spinner/>
                    </div>
                </AppFrame>
            </>;
        } else if (this.state.error) {
            return <>
                <AppFrame>
                    <ErrorBox error={this.state.error}/>
                </AppFrame>
            </>;
        }

        return <AppFrame>

            <div className="row">

                <div className="col">

                    <Carousel   items       = {this.state.choices}
                                renderItem  = {item => item ? item.title : Strings.trends}
                                selected    = {this.state.current}
                                onChange    = {this.onPageChange.bind(this)}
                                style       = {{height: '2.25rem'}}/>

                    <div className={`d-flex align-items-center justify-content-${this.context.user.isAdmin ? 'between' : 'center'} flex-column flex-md-row`}>
                        <ButtonGroup    items       = {['months', 'quarters', 'years']}
                                        className   = "mt-3"
                                        renderItem  = {item => ({months: Strings.monthly, quarters: Strings.quarterly, years: Strings.yearly})[item]}
                                        selected    = {this.state.viewMode}
                                        onChange    = {this.onViewModeChange.bind(this)}/>
                        {this.context.user.isAdmin &&
                            <Select label       = {Strings.viewAs}
                                    className   = "mt-3"
                                    items       = {this.state.users}
                                    renderItem  = {user => user.username}
                                    itemValue   = {user => user.id}
                                    selected    = {this.state.viewAs}
                                    onChange    = {user => this.onViewAsChange(user)}/>
                        }
                    </div>

                    {this.state.current && <>

                        <div className="d-flex mt-3">

                            <ProductSummaryTable    className="mt-3"
                                                    products={this.state.products}
                                                    current={this.state.current}/>

                        </div>

                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                                <button type            = "button"
                                        className       = "btn btn-primary"
                                        onClick         = {this.onExportCsvClick.bind(this)}>
                                    <FontAwesomeIcon icon={faFileCsv} className="mr-2"/>
                                    {Strings.exportCsv}
                                </button>
                                {this.state.viewMode === 'quarters' && this.state.current.number < CURRENT_QUARTER() &&
                                    <button type            = "button"
                                            className       = "btn btn-primary"
                                            onClick         = {() => this.onExportPdfClick()}
                                            onTouchStart    = {this.onExportPdfPress.bind(this)}
                                            onTouchEnd      = {this.onExportPdfRelease.bind(this)}
                                            onMouseDown     = {this.onExportPdfPress.bind(this)}
                                            onMouseUp       = {this.onExportPdfRelease.bind(this)}
                                            onMouseLeave    = {this.onExportPdfRelease.bind(this)}>
                                        <FontAwesomeIcon icon={faFilePdf} className="mr-2"/>
                                        {Strings.exportPdf}
                                    </button>
                                }
                            </div>
                            <div className="flex-grow-1 text-right text-info">
                                {Strings.total}: <big><b>{Utils.formatUsd00(this.state.current.total)}</b></big>
                            </div>
                        </div>

                        <div className="mt-5 d-flex align-items-center flex-column flex-md-row">
                            <div className="d-flex flex-grow-1" style={{height: '15rem', overflow: 'hidden'}}>
                                <ProductBarChart className="flex-grow-1" item={this.state.current} products={this.state.products}/>
                            </div>
                            <div className="mt-3 mt-md-0 d-flex ml-5" style={{height: '15rem', width: '13rem', overflow: 'hidden'}}>
                                <PlatformPieChart className="flex-grow-1" item={this.state.current} platforms={this.state.platforms}/>
                            </div>
                        </div>

                    </>}

                    {!this.state.current &&
                        <div className="mt-5 d-flex" style={{height: '25rem'}}>
                            <TrendLineChart className="flex-grow-1"
                                            months={this.state.months.slice(Math.max(0, this.state.months.length - 12))}
                                            products={this.state.products}/>
                        </div>}

                </div>

            </div>

        </AppFrame>;
    }

};

HomePage.contextType = SessionContext;