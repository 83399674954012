//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                                from 'react';
import ReactDOM                             from 'react-dom';
import { BrowserRouter, Redirect, Switch }  from 'react-router-dom';
import ProtectedRoute                       from './components/ProtectedRoute';
import SessionCheck                         from './components/SessionCheck';
import AddOrEditProductPage                 from './pages/AddOrEditProductPage';
import AddOrEditUserPage                    from './pages/AddOrEditUserPage';
import ChangePasswordPage                   from './pages/ChangePasswordPage';
import HomePage                             from './pages/HomePage';
import LoginPage                            from './pages/LoginPage';
import LogoutPage                           from './pages/LogoutPage';
import ProductsPage                         from './pages/ProductsPage';
import UploadPage                           from './pages/UploadPage';
import UsersPage                            from './pages/UsersPage';

import './index.css';

ReactDOM.render(
    <React.StrictMode>
        <SessionCheck>
            <BrowserRouter>
                <Switch>
                    <ProtectedRoute path="/login"               exact component={LoginPage}/>
                    <ProtectedRoute path="/logout"              exact component={LogoutPage}/>
                    <ProtectedRoute path="/"                    exact component={HomePage}              loginRequired/>
                    <ProtectedRoute path="/password"            exact component={ChangePasswordPage}    loginRequired/>
                    <ProtectedRoute path="/admin/products"      exact component={ProductsPage}          loginRequired adminRequired/>
                    <ProtectedRoute path="/admin/products/add"  exact component={AddOrEditProductPage}  loginRequired adminRequired/>
                    <ProtectedRoute path="/admin/products/:id"        component={AddOrEditProductPage}  loginRequired adminRequired/>
                    <ProtectedRoute path="/admin/upload"        exact component={UploadPage}            loginRequired adminRequired/>
                    <ProtectedRoute path="/admin/users"         exact component={UsersPage}             loginRequired adminRequired/>
                    <ProtectedRoute path="/admin/users/add"     exact component={AddOrEditUserPage}     loginRequired adminRequired/>
                    <ProtectedRoute path="/admin/users/:id"           component={AddOrEditUserPage}     loginRequired adminRequired/>
                    <Redirect to="/"/>
                </Switch>
            </BrowserRouter>
        </SessionCheck>
    </React.StrictMode>,
    document.getElementById('root'));