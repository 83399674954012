//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React        from 'react';
import CenterScreen from '../components/widgets/CenterScreen';
import Spinner      from '../components/widgets/Spinner';
import logo         from '../images/staffpad.png';

//
// A full-screen loading page which renders the application logo and a spinner in the center of
// the window.
//
export default () =>
    <CenterScreen>
        <img src={logo} style={{height: '10vh'}} alt="StaffPad Partner Dashboard"/>
        <Spinner className="mt-3"/>
    </CenterScreen>;