//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import Async                from 'react-async';
import { useHistory }       from "react-router-dom";
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import Strings              from '../Strings';
import AppFrame             from '../components/AppFrame';
import DataTable            from '../components/widgets/DataTable';
import ErrorBox             from '../components/widgets/ErrorBox';
import Spinner              from '../components/widgets/Spinner';
import SessionContext       from '../utils/SessionContext';

import {
    faPlusSquare,
    faSyncAlt
}  from '@fortawesome/free-solid-svg-icons';

export default () => {

    const history = useHistory();
    const session = React.useContext(SessionContext);

    return <Async   promiseFn   = {({backend}) => backend.getProducts()}
                    backend     = {session.backend}>{({isPending, reload}) =>

        <AppFrame>
            <div className="row">
                <div className="col">

                    <div className="d-flex align-items-center mb-4">

                        <h3 className="text-light m-0 p-0 flex-grow-1">{Strings.products}</h3>

                        <Async.Pending>
                            <Spinner className="mr-2 spinner-border-sm"/>
                        </Async.Pending>

                        <button className   = "btn btn-primary ml-1"
                                onClick     = {reload}
                                disabled    = {isPending}
                                title       = {Strings.refreshProductList}>
                            <FontAwesomeIcon icon={faSyncAlt} className="mr-2"/>
                            {Strings.refresh}
                        </button>

                        <button className   = "btn btn-info ml-1"
                                onClick     = {() => history.push('/admin/products/add')}
                                title       = {Strings.addProduct}>
                            <FontAwesomeIcon icon={faPlusSquare} className="mr-2"/>
                            {Strings.add}
                        </button>

                    </div>

                    <Async.Rejected>
                        <ErrorBox error={Strings.serverError}/>
                    </Async.Rejected>

                    <Async.Resolved persist>{products => products.length === 0

                        ?   <div className="alert alert-primary">
                                {Strings.noProducts}
                            </div>

                        :   <DataTable
                                className   = "table-hover"
                                items       = {products}
                                itemKey     = {product => product.id}
                                onItemClick = {product => history.push('/admin/products/' + product.id)}
                                columns     = {[
                                    {
                                        title           : Strings.productName,
                                        renderItem      : product => product.name,
                                    }, {
                                        title           : Strings.share,
                                        align           : 'right',
                                        renderItem      : product => (100 * product.partnerShare).toFixed(0) + '%',
                                        valueToCompare  : [product => product.partnerShare, 1, product => product.name]
                                    }
                            ]}/>

                    }</Async.Resolved>

                </div>
            </div>
        </AppFrame>

    }</Async>;

};