//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React, { useState }  from 'react';
import Utils                from '../../utils/Utils';

export default props => {

    const selectId = useState(Utils.uniqueId());

    return <>

        <div    id          = {props.id}
                className   = {`input-group ${props.className || ''}`}
                style       = {{width: 'auto', ...props.style}}>

            <div className="input-group-prepend">
                <label className="input-group-text bg-info border-0 text-light" htmlFor={selectId}>
                    {props.label}
                </label>
            </div>

            <select id          = {selectId}
                    className   = "custom-select bg-primary border-0 text-light"
                    style       = {{width: '10rem'}}
                    value       = {(props.selected && props.itemValue ? props.itemValue(props.selected) : props.selected) || ''}
                    onChange    = {e => props.onChange && props.onChange(e.target.selectedIndex >= 0 ? props.items[e.target.selectedIndex] : null)}>

                {props.items && props.items.map(item =>

                    <option key     = {props.itemValue ? props.itemValue(item) : item}
                            value   = {props.itemValue ? props.itemValue(item) : item}>
                        {props.renderItem ? props.renderItem(item) : item}
                    </option>

                )};

          </select>

        </div>

    </>;

};