//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import { withRouter }       from "react-router-dom";
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import AppFrame             from '../components/AppFrame';
import DataTable            from '../components/widgets/DataTable';
import Spinner              from '../components/widgets/Spinner';
import SessionContext       from '../utils/SessionContext';
import Utils                from '../utils/Utils';

import { faCheck, faChevronLeft, faHistory, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

class AddOrEditProductsPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading             : false,
            error               : null,
            name                : '',
            partnerShare        : '',
            externalIds         : [],
            addingExternalId    : false,
            newExternalId       : null,
            newPlatform         : null,
            product             : null,
            wantFocus           : false,
        };

        this.columns = [
            {
                title           : 'External ID',
                renderItem      : externalId => externalId.externalId,
            }, {
                title           : 'Platform',
                align           : 'right',
                renderItem      : externalId => externalId.platform,
                compare         : (a, b) => a.platform.localeCompare(b.platform) || a.externalId.localeCompare(b.externalId),
            }, {
                title           : 'Del',
                align           : 'right',
                sortable        : false,
                renderItem      : externalId =>
                    <FontAwesomeIcon    icon={faTimes}
                                        className="link text-danger"
                                        onClick={this.onDeleteExternalIdClick.bind(this, externalId)}/>,
            }
        ];

    }

    canEdit() {
        return !this.state.loading && this.state.product;
    }

    compareExternalIds(a, b) {
        return a.externalId.localeCompare(b.externalId);
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.loadData();
        } else {
            this.setStateFromProduct({});
        }
    }

    isProductValid(product) {
        if (product.name === '' ||
                product.partnerShare < 0 ||
                product.partnerShare > 1) {
            return false;
        }
        for (let i = 0; i < product.externalIds.length; ++i) {
            if (product.externalIds[i].externalId === '' ||
                    product.externalIds[i].platform === '') {
                return false;
            }
        }
        return true;
    }

    async loadData() {
        this.setState({error: null, loading: true});
        try {
            const product = await this.context.backend.getProduct(this.props.match.params.id);
            this.setStateFromProduct(product);
        } catch (error) {
            console.error(error);
            this.setState({product: null, error: 'Sorry, an error occurred. Please try refreshing.'}, Utils.scrollToTop);
        } finally {
            this.setState({loading: false});
        }
    }

    onAddExternalIdClick() {
        if (this.canEdit()) {
            this.setState({addingExternalId: true, wantFocus: true, newExternalId: '', newPlatform: ''});
        }
    }

    onBackClick() {
        this.props.history.push('/admin/products');
    }

    onCancelExternalIdClick() {
        this.setState({addingExternalId: false});
    }

    onConfirmExternalIdClick() {
        if (this.state.addingExternalId) {
            const externalId = this.state.newExternalId.trim();
            const platform = this.state.newPlatform.trim();
            if (externalId !== '' && platform !== '') {
                const row = {externalId: externalId, platform: platform};
                this.setState({addingExternalId: false, externalIds: this.state.externalIds.concat(row).sort(this.compareExternalIds)});
            }
        }
    }

    async onDeleteClick() {

        this.setState({loading: true});

        try {
            await this.context.backend.deleteProduct(this.props.match.params.id);
            this.props.history.push('/admin/products');
        } catch (error) {
            this.setState({loading: false, error: 'Sorry, an error occurred. Please try refreshing.'}, Utils.scrollToTop);
        }

    }

    onDeleteExternalIdClick(externalId) {
        this.setState({externalIds: this.state.externalIds.filter(row => row.externalId !== externalId.externalId)});
    }

    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.onConfirmExternalIdClick();
        }
    }

    onResetClick() {
        this.setStateFromProduct(this.state.product);
    }

    async onSaveClick() {

        const product = {
            id              : this.props.match.params.id,
            name            : this.state.name.trim(),
            partnerShare    : Number(this.state.partnerShare) / 100,
            externalIds     : [...this.state.externalIds],
        };

        if (this.state.addingExternalId) {
            product.externalIds.push({
                externalId: this.state.newExternalId.trim(),
                platform: this.state.newPlatform.trim()
            });
        }

        if (!this.isProductValid(product)) {
            return this.setState({error: 'One or more fields are invalid. Please check them and try again.'}, Utils.scrollToTop);
        }

        this.setState({loading: true});

        try {
            await this.context.backend[this.props.match.params.id ? 'updateProduct' : 'createProduct'](product);
            this.props.history.push('/admin/products');
        } catch (error) {
            this.setState({loading: false, error: 'Sorry, an error occurred. Please try refreshing.'}, Utils.scrollToTop);
        }

    }

    setStateFromProduct(product) {
        this.setState({
            name            : product.name || '',
            partnerShare    : String((product.partnerShare * 100) || ''),
            externalIds     : (product.externalIds || []).sort(this.compareExternalIds),
            addingExternalId: false,
            newExternalId   : '',
            newPlatform     : '',
            product         : product,
        });
    }

    render() {

        return <AppFrame>

            <div className="row mb-4">
                <div className="col d-flex align-items-center">
                    <h3 className="text-light m-0 p-0 flex-grow-1">
                        <FontAwesomeIcon    icon={faChevronLeft}
                                            className="link mr-3 text-secondary"
                                            onClick={this.onBackClick.bind(this)}/>
                        {this.props.match.params.id ? 'Edit' : 'Add'} Product
                    </h3>
                    {this.state.loading &&
                        <Spinner className="spinner-border-sm"/>
                    }
                </div>
            </div>

            {this.state.error &&
                <div className="row">
                    <div className="col">
                        <div className="alert alert-danger">
                            {this.state.error}
                        </div>
                    </div>
                </div>
            }

            <form>

                <div className="form-group row">
                    <div className="col d-flex align-items-center">
                        <label  htmlFor="input-name"
                                className="d-inline-block mr-3 my-0">
                            Name
                        </label>
                        <input  type="text"
                                id="input-name"
                                className="form-control flex-grow-1"
                                placeholder="Product name"
                                value={this.state.name}
                                onChange={Utils.onFieldChange.bind(this, 'name')}
                                disabled={!this.canEdit()}/>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col d-flex align-items-center">
                        <label  htmlFor="input-partner-share"
                                className="d-inline-block mr-3 my-0 text-nowrap">
                            Partner share
                        </label>
                        <div className="input-group flex-grow-1">
                            <input  type="number"
                                    id="input-partner-share"
                                    className="form-control"
                                    style={{maxWidth: '15rem'}}
                                    placeholder="Partner share percentage, e.g. 50"
                                    value={this.state.partnerShare}
                                    onChange={Utils.onFieldChange.bind(this, 'partnerShare')}
                                    disabled={!this.canEdit()}/>
                            <div className="input-group-append">
                                <span className="input-group-text bg-secondary text-dark">%</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h4 className="text-light mt-3 mb-2">External Product IDs</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col d-flex">
                        <DataTable  className   = "table-hover flex-grow-1"
                                    columns     = {this.columns}
                                    items       = {this.state.externalIds}
                                    itemKey     = {externalId => externalId.externalId}/>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col">
                        <div className="d-flex align-items-center">
                            {this.state.addingExternalId ? <>
                                <input  type="text"
                                        ref={el => el && this.state.wantFocus && el.focus()}
                                        className="form-control form-control-sm mr-1"
                                        placeholder="External ID"
                                        value={this.state.newExternalId}
                                        onChange={Utils.onFieldChange.bind(this, 'newExternalId')}
                                        onKeyDown={this.onKeyDown.bind(this)}
                                        onFocus={() => this.setState({wantFocus: false})}
                                        disabled={!this.canEdit()}/>
                                <input  type="text"
                                        className="form-control form-control-sm ml-1"
                                        placeholder="Platform name"
                                        value={this.state.newPlatform}
                                        onChange={Utils.onFieldChange.bind(this, 'newPlatform')}
                                        onKeyDown={this.onKeyDown.bind(this)}
                                        disabled={!this.canEdit()}/>
                                <FontAwesomeIcon    icon={faCheck}
                                                    className="link text-success ml-3"
                                                    onClick={this.onConfirmExternalIdClick.bind(this)}/>
                                <FontAwesomeIcon    icon={faTimes}
                                                    className="link text-danger ml-3"
                                                    onClick={this.onCancelExternalIdClick.bind(this)}/>
                            </> : <>
                                <FontAwesomeIcon    icon={faPlus}
                                                    className="link text-succses"
                                                    onClick={this.onAddExternalIdClick.bind(this)}/>
                            </>}
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col">
                        <button type="button"
                                className="btn btn-success"
                                onClick={this.onSaveClick.bind(this)}
                                disabled={!this.canEdit()}>
                            <FontAwesomeIcon icon={faCheck}/> Save
                        </button>
                        <button type="button"
                                className="btn btn-primary ml-2"
                                onClick={this.onResetClick.bind(this)}
                                disabled={!this.canEdit()}>
                            <FontAwesomeIcon icon={faHistory}/> Reset
                        </button>
                    </div>
                </div>

                {this.props.match.params.id &&
                    <div className="row mt-4">
                        <div className="col">
                            <h4 className="text-light my-3">Delete Product</h4>
                            <button type="button"
                                    className="btn btn-danger"
                                    onClick={this.onDeleteClick.bind(this)}
                                    disabled={!this.canEdit()}>
                                <FontAwesomeIcon icon={faTimes}/> Delete
                            </button>
                        </div>
                    </div>
                }

            </form>

        </AppFrame>;
    }

};

AddOrEditProductsPage.contextType = SessionContext;
export default withRouter(AddOrEditProductsPage);