//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import { withRouter }       from "react-router-dom";
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import AppFrame             from '../components/AppFrame';
import DataTable            from '../components/widgets/DataTable';
import Spinner              from '../components/widgets/Spinner';
import SessionContext       from '../utils/SessionContext';
import Utils                from '../utils/Utils';

import {
    faCheck,
    faChevronLeft,
    faHistory,
    faPlus,
    faTimes
} from '@fortawesome/free-solid-svg-icons';

class AddOrEditUsersPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading             : false,
            error               : null,
            username            : '',
            name                : '',
            password            : '',
            lastActivity        : '',
            isAdmin             : false,
            statementName       : '',
            statementAddress    : '',
            currency            : '',
            currencySymbol      : '',
            vatNumber           : '',
            vatPercent          : 0.0,
            products            : [],
            addingProduct       : false,
            newProductId        : null,
            user                : null,
            allProducts         : [],
            wantFocus           : false,
        };

        this.newProductSelect = React.createRef();

        this.columns = [
            {
                title           : 'Product',
                renderItem      : product => product.name,
            }, {
                title           : 'Del',
                align           : 'right',
                sortable        : false,
                renderItem      : product =>
                    <FontAwesomeIcon    icon={faTimes}
                                        className="link text-danger"
                                        onClick={this.onDeleteProductClick.bind(this, product)}/>,
            }
        ];

    }

    canEdit() {
        return !this.state.loading && this.state.user;
    }

    compareProducts(a, b) {
        return a.name.localeCompare(b.name);
    }

    async componentDidMount() {
        if (this.props.match.params.id) {
            this.loadData();
        } else {
            this.setState({error: null, loading: true});
            try {
                const products = await this.context.backend.getProducts();
                this.setState({allProducts: products});
                this.setStateFromUser({});
            } catch (error) {
                console.error(error);
            } finally {
                this.setState({loading: false});
            }
        }
    }

    getAvailableProducts() {
        const ids = {};
        this.state.products.forEach(product => ids[product.id] = true);
        return this.state.allProducts.filter(product => !ids[product.id]).sort(this.compareProducts);
    }

    isUserValid(user) {
        if (user.username === '' ||
                user.name === '' ||
                (!this.props.match.params.id && user.password === '')) {
            return false;
        }
        return true;
    }

    async loadData() {
        this.setState({error: null, loading: true});
        try {
            const user = await this.context.backend.getUser(this.props.match.params.id);
            const products = await this.context.backend.getProducts();
            this.setState({allProducts: products});
            this.setStateFromUser(user);
        } catch (error) {
            console.error(error);
            this.setState({user: null, products: [], error: 'Sorry, an error occurred. Please try refreshing.'}, Utils.scrollToTop);
        } finally {
            this.setState({loading: false});
        }
    }

    onAddProductClick() {
        if (this.canEdit()) {
            this.setState({addingProduct: true, wantFocus: true});
        }
    }

    onBackClick() {
        this.props.history.push('/admin/users');
    }

    onCancelAddProductClick() {
        this.setState({addingProduct: false});
    }

    onConfirmAddProductClick() {
        if (this.state.addingProduct) {
            const select = this.newProductSelect.current;
            if (select.options.selectedIndex >= 0) {
                const product = {
                    id: select.value,
                    name: select.options[select.options.selectedIndex].text,
                };
                this.setState({addingProduct: false, products: this.state.products.concat(product).sort(this.compareProducts)});
            }
        }
    }

    async onDeleteClick() {

        this.setState({loading: true});

        try {
            await this.context.backend.deleteUser(this.props.match.params.id);
            this.props.history.push('/admin/users');
        } catch (error) {
            this.setState({loading: false, error: 'Sorry, an error occurred. Please try refreshing.'}, Utils.scrollToTop);
        }

    }

    onDeleteProductClick(product) {
        this.setState({products: this.state.products.filter(row => row.id !== product.id)});
    }

    onResetClick() {
        this.setStateFromUser(this.state.user);
    }

    async onSaveClick() {

        const user = {
            id                  : this.props.match.params.id,
            username            : this.state.username,
            name                : this.state.name,
            password            : this.state.password,
            lastActivity        : this.state.lastActivity,
            isAdmin             : this.state.isAdmin,
            statementName       : this.state.statementName,
            statementAddress    : this.state.statementAddress,
            currency            : this.state.currency,
            currencySymbol      : this.state.currencySymbol,
            vatNumber           : this.state.vatNumber,
            vatPercent          : this.state.vatPercent,
            products            : [...this.state.products],
        };

        if (this.state.addingProduct && this.newProductSelect && this.newProductSelect.current) {
            user.products.push({id: this.newProductSelect.current.value});
        }

        if (!this.isUserValid(user)) {
            return this.setState({error: 'One or more fields are invalid. Please check them and try again.'}, Utils.scrollToTop);
        }

        this.setState({loading: true});

        try {
            await this.context.backend[this.props.match.params.id ? 'updateUser' : 'createUser'](user);
            this.props.history.push('/admin/users');
        } catch (error) {
            this.setState({loading: false, error: 'Sorry, an error occurred. Please try refreshing.'}, Utils.scrollToTop);
        }

    }

    setStateFromUser(user) {
        this.setState({
            username            : user.username || '',
            name                : user.name || '',
            isAdmin             : user.isAdmin || false,
            lastActivity        : user.lastActivity,
            statementName       : user.statementName || '',
            statementAddress    : user.statementAddress || '',
            currency            : user.currency,
            currencySymbol      : user.currencySymbol,
            vatNumber           : user.vatNumber || '',
            vatPercent          : user.vatPercent,
            products            : (user.products || []).sort(this.compareProducts),
            addingProduct       : false,
            newProductId        : '',
            user                : user,
        });
    }

    render() {

        return <AppFrame>

            <div className="row mb-4">
                <div className="col d-flex align-items-center">
                    <h3 className="text-light m-0 p-0 flex-grow-1">
                        <FontAwesomeIcon    icon={faChevronLeft}
                                            className="link mr-3 text-secondary"
                                            onClick={this.onBackClick.bind(this)}/>
                        {this.props.match.params.id ? 'Edit' : 'Add'} User
                    </h3>
                    {this.state.loading &&
                        <Spinner className="spinner-border-sm"/>
                    }
                </div>
            </div>

            {this.state.error &&
                <div className="row">
                    <div className="col">
                        <div className="alert alert-danger">
                            {this.state.error}
                        </div>
                    </div>
                </div>
            }

            <form>

                <div className="form-group row">
                    <div className="col d-flex align-items-center">
                        <label  htmlFor="input-username"
                                className="d-inline-block mr-3 my-0">
                            Username
                        </label>
                        <input  type="text"
                                id="input-username"
                                className="form-control flex-grow-1"
                                placeholder="Username"
                                disabled={!this.canEdit()}
                                value={this.state.username}
                                onChange={Utils.onFieldChange.bind(this, 'username')}
                                autoComplete="off"/>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col d-flex align-items-center">
                        <label htmlFor="input-name" className="d-inline-block mr-3 my-0">
                            Name
                        </label>
                        <input  type="text"
                                id="input-name"
                                className="form-control flex-grow-1"
                                placeholder="Name"
                                disabled={!this.canEdit()}
                                value={this.state.name}
                                onChange={Utils.onFieldChange.bind(this, 'name')}
                                autoComplete="off"/>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col d-flex align-items-center">
                        <label htmlFor="input-password" className="d-inline-block mr-3 my-0">
                            Password
                        </label>
                        <input  type="password"
                                id="input-password"
                                className="form-control flex-grow-1"
                                placeholder={this.props.match.params.id ? 'Leave blank or enter a new password' : 'Password'}
                                disabled={!this.canEdit()}
                                value={this.state.password}
                                onChange={Utils.onFieldChange.bind(this, 'password')}
                                autoComplete="new-password"/>
                    </div>
                </div>

                <div className="form-group row mt-4">

                    <div className="col d-flex align-items-center">
                        <label htmlFor="input-isAdmin" className="d-inline-block mr-3 my-0">
                            Administrator
                        </label>
                        <input  type="checkbox"
                                id="input-isAdmin"
                                disabled={!this.canEdit()}
                                checked={this.state.isAdmin}
                                onChange={Utils.onFieldChange.bind(this, 'isAdmin')}/>
                        <span className="mr-2 text-right flex-grow-1">Last activity:</span>
                        <b>{this.state.lastActivity}</b>
                    </div>

                </div>

                <div className="row mt-5 mb-2">
                    <div className="col">
                        <h4 className="text-light">Statement Generation</h4>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col d-flex align-items-center">
                        <label  htmlFor="input-statement-name"
                                className="d-inline-block mr-3 my-0 text-nowrap">
                            Name on statement
                        </label>
                        <input  type="text"
                                id="input-statement-name"
                                className="form-control flex-grow-1"
                                placeholder="Name on statement"
                                disabled={!this.canEdit()}
                                value={this.state.statementName}
                                onChange={Utils.onFieldChange.bind(this, 'statementName')}
                                autoComplete="off"/>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col d-flex align-items-center">
                        <label  htmlFor="input-statement-address"
                                className="d-inline-block mr-3 my-0 text-nowrap">
                            Address on statement
                        </label>
                        <textarea
                                id="input-statement-address"
                                className="form-control flex-grow-1"
                                rows="4"
                                placeholder="Address on statement"
                                disabled={!this.canEdit()}
                                value={this.state.statementAddress}
                                onChange={Utils.onFieldChange.bind(this, 'statementAddress')}
                                autoComplete="off"/>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col d-flex align-items-center">
                        <label  htmlFor="input-currency"
                                className="d-inline-block mr-3 my-0 text-nowrap">
                            Currency
                        </label>
                        <input  type="text"
                                id="input-currency"
                                className="form-control flex-grow-1"
                                placeholder="Currency"
                                disabled={!this.canEdit()}
                                value={this.state.currency}
                                onChange={Utils.onFieldChange.bind(this, 'currency')}
                                autoComplete="off"/>
                    </div>
                    <div className="col d-flex align-items-center">
                        <label  htmlFor="input-currency-symbol"
                                className="d-inline-block mr-3 my-0 text-nowrap">
                            Symbol
                        </label>
                        <input  type="text"
                                id="input-currency-symbol"
                                className="form-control flex-grow-1"
                                placeholder="Currency symbol"
                                disabled={!this.canEdit()}
                                value={this.state.currencySymbol}
                                onChange={Utils.onFieldChange.bind(this, 'currencySymbol')}
                                autoComplete="off"/>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col d-flex align-items-center">
                        <label  htmlFor="input-vat-number"
                                className="d-inline-block mr-3 my-0 text-nowrap">
                            VAT number
                        </label>
                        <input  type="text"
                                id="input-vat-number"
                                className="form-control flex-grow-1"
                                placeholder="VAT number"
                                disabled={!this.canEdit()}
                                value={this.state.vatNumber}
                                onChange={Utils.onFieldChange.bind(this, 'vatNumber')}
                                autoComplete="off"/>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col d-flex align-items-center">
                        <label  htmlFor="input-vat-percent"
                                className="d-inline-block mr-3 my-0 text-nowrap">
                            VAT percent
                        </label>
                        <div className="input-group flex-grow-1">
                            <input  type="number"
                                    id="input-vat-percent"
                                    className="form-control"
                                    style={{maxWidth: '15rem'}}
                                    placeholder="VAT percentage, e.g. 20"
                                    value={this.state.vatPercent}
                                    onChange={Utils.onFieldChange.bind(this, 'vatPercent')}
                                    disabled={!this.canEdit()}/>
                            <div className="input-group-append">
                                <span className="input-group-text bg-secondary text-dark">%</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 mb-2">
                    <div className="col">
                        <h4 className="text-light">Assigned Products</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col d-flex">
                        <DataTable  className   = "table-hover flex-grow-1"
                                    columns     = {this.columns}
                                    items       = {this.state.products}
                                    itemKey     = {product => product.id}/>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col">
                        <div className="d-flex align-items-center">
                            {this.state.addingProduct ? <>
                                <select className="form-control"
                                        id="input-new-product"
                                        ref={this.newProductSelect}
                                        value={this.state.newProductId}
                                        onChange={Utils.onFieldChange.bind(this, 'newProductId')}>
                                    {this.getAvailableProducts().map(product => <option key={product.id} value={product.id}>{product.name}</option>)}
                                </select>
                                <FontAwesomeIcon icon={faCheck} className="link text-success ml-3" onClick={this.onConfirmAddProductClick.bind(this)}/>
                                <FontAwesomeIcon icon={faTimes} className="link text-danger ml-3" onClick={this.onCancelAddProductClick.bind(this)}/>
                            </> : <>
                                <FontAwesomeIcon icon={faPlus} className="link text-succses" onClick={this.onAddProductClick.bind(this)}/>
                            </>}
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col">
                        <button type="button"
                                className="btn btn-success"
                                onClick={this.onSaveClick.bind(this)}
                                disabled={!this.canEdit()}>
                            <FontAwesomeIcon icon={faCheck}/> Save
                        </button>
                        <button type="button"
                                className="btn btn-primary ml-2"
                                onClick={this.onResetClick.bind(this)}
                                disabled={!this.canEdit()}>
                            <FontAwesomeIcon icon={faHistory}/> Reset
                        </button>
                    </div>
                </div>

                {this.props.match.params.id &&
                    <div className="row mt-4">
                        <div className="col">
                            <h4 className="text-light mb-3">Delete User</h4>
                            <button type="button"
                                    className="btn btn-danger"
                                    onClick={this.onDeleteClick.bind(this)}
                                    disabled={!this.canEdit()}>
                                <FontAwesomeIcon icon={faTimes}/> Delete
                            </button>
                        </div>
                    </div>
                }

            </form>

        </AppFrame>;
    }

};

AddOrEditUsersPage.contextType = SessionContext;
export default withRouter(AddOrEditUsersPage);