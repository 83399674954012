//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React                from 'react';
import { withRouter }       from "react-router-dom";
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import Strings              from '../Strings';
import CenterScreen         from '../components/widgets/CenterScreen';
import ErrorBox             from '../components/widgets/ErrorBox';
import Spinner              from '../components/widgets/Spinner';
import logo                 from '../images/staffpad.png';
import SessionContext       from '../utils/SessionContext';
import Utils                from '../utils/Utils';

import {
    faKey,
    faSignInAlt,
    faUser
} from '@fortawesome/free-solid-svg-icons';

class LoginPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading     : false,
            error       : null,
            username    : '',
            password    : '',
            remember    : false,
        };

        this.usernameInput = React.createRef();
        this.passwordInput = React.createRef();

    }

    componentDidMount() {
        this.usernameInput.current.focus();
    }

    async onLoginClick() {

        if (!this.state.username.trim()) {
            this.usernameInput.current.focus();
            this.usernameInput.current.select();
            return;
        } else if (!this.state.password) {
            this.passwordInput.current.focus();
            return;
        }

        this.setState({loading: true});

        try {

            const user = await this.context.backend.login(
                this.state.username.trim(),
                this.state.password,
                this.state.remember);

            if (user) {
                this.context.set(user);
                this.props.history.push('/');
            } else {
                this.setState({loading: false, error: Strings.invalidCredentials},
                    () => this.passwordInput.current.focus(), this.passwordInput.current.select());
            }

        } catch (error) {
            console.error(error);
            this.setState({loading: false, error: Strings.serverError});
        }

    }

    render() {

        return <CenterScreen className="container">

            <div className="row justify-content-center">

                <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4 text-center">

                    <img src={logo} style={{width: '60%'}} className="mb-5" alt="StaffPad Partner Dashboard"/>

                    <form className="mt-2">

                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text bg-secondary d-flex justify-content-center" style={{width: '3.25rem'}}>
                                    <FontAwesomeIcon icon={faUser} style={{color: 'black'}}/>
                                </span>
                            </div>
                            <input  type            = "text"
                                    ref             = {this.usernameInput}
                                    className       = "form-control"
                                    placeholder     = {Strings.username}
                                    value           = {this.state.username}
                                    onChange        = {Utils.onFieldChange.bind(this, 'username')}
                                    disabled        = {this.state.loading}
                                    autoComplete    = "username"/>
                        </div>

                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text bg-secondary d-flex justify-content-center" style={{width: '3.25rem'}}>
                                    <FontAwesomeIcon icon={faKey} style={{color: 'black'}}/>
                                </span>
                            </div>
                            <input  type            = "password"
                                    ref             = {this.passwordInput}
                                    className       = "form-control"
                                    placeholder     = {Strings.password}
                                    value           = {this.state.password}
                                    onChange        = {Utils.onFieldChange.bind(this, 'password')}
                                    disabled        = {this.state.loading}
                                    autoComplete    = "current-password"/>
                        </div>

                        <ErrorBox className="mt-2 text-center" error={this.state.error}/>

                        <div className="row mt-3">
                            <div className="col d-flex align-items-center">
                                <label className="flex-grow-1 m-0 d-flex ml-1 align-items-center">
                                    <input  type        = "checkbox"
                                            className   = "form-control text-light"
                                            value       = {this.state.remember}
                                            onChange    = {Utils.onFieldChange.bind(this, 'remember')}
                                            disabled    = {this.state.loading}/>
                                    {Strings.stayLoggedIn}
                                </label>
                                {this.state.loading && <Spinner className="mr-3"/>}
                                <button type        = "button"
                                        className   = "btn btn-info"
                                        disabled    = {this.state.loading}
                                        onClick     = {this.onLoginClick.bind(this)}>
                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2"/>
                                    {Strings.logIn}
                                </button>
                            </div>
                        </div>

                    </form>

                </div>

            </div>

        </CenterScreen>;

    }

}

LoginPage.contextType = SessionContext;
export default withRouter(LoginPage);