//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React from 'react';

//
// The SessionContext holds an object value with the following properties:
//
// backend - A Backend instance which can be used to make backend API calls.
// user - An object describing the user to whom the current session belongs, or null if there is
//        no active session.
// set  - A function which can be called to ask the SessionContext provider (specifically, the
//        <SessionCheck> component) to set new user data.
//

export default React.createContext(null);