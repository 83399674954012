//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React            from 'react';
import { withRouter }   from 'react-router-dom';
import LoadingPage      from '../pages/LoadingPage';
import SessionContext   from '../utils/SessionContext';

class LogoutPage extends React.Component {

    async componentDidMount() {
        try {
            await this.context.backend.logout();
        } catch (error) {
            console.error(error);
        } finally {
            this.context.set(null);
            this.props.history.push('/login');
        }
    }

    render() {
        return <LoadingPage/>;
    }

}

LogoutPage.contextType = SessionContext;
export default withRouter(LogoutPage);