//
// Copyright (C) 2020 StaffPad Ltd. All rights reserved.
//

import React from 'react';

import NavBar from './NavBar';

import './AppFrame.css';

export default function AppFrame(props) {

    return <>

        <header>
            <NavBar/>
        </header>

        <main role="main" className="container mt-4">
            {props.children}
        </main>

    </>;
};